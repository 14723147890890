import React, { useState, useEffect } from "react";
import {
  Button,
  Box,
  Card,
  Divider,
  Group,
  Text,
  Title,
  Flex,
} from "@mantine/core";
import {
  IconRectangleVerticalFilled,
  IconHeadphones,
  IconThumbUp,
  IconThumbDown,
  IconScoreboard,
} from "@tabler/icons-react";

import { SongBurnIcon } from "./";
import abbreviateNumber from "@util/abbreviateNumber";

export default function SongRating({ info, isAlltime, defaultOpen }) {
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    if (defaultOpen) {
      setOpen(true);
    }
  }, [defaultOpen]);
  const isLess = isAlltime
    ? info.all_time_formatted_rating
      ? info.all_time_formatted_rating.indexOf("L") > -1
      : false
    : info.current_formatted_rating
    ? info.current_formatted_rating.indexOf("L") > -1
    : false;

  const openStats = {
    burn_rate: isAlltime ? info.all_time_burn_rate : info.current_burn_rate,
    score: isAlltime ? info.all_time_score : info.current_score,
    sample_size: isAlltime
      ? info.all_time_sample_size
      : info.current_sample_size,
    formatted_rating: isAlltime
      ? info.all_time_formatted_rating
      : info.current_formatted_rating,
    conviction: isAlltime ? info.all_time_conviction : info.current_conviction,
  };

  return (
    <>
      <Flex gap="md" align="center">
        <Flex direction="column" align="center">
          <SongBurnIcon burn={openStats.burn_rate} />
          <Text c="dimmed" size="xs">
            {openStats.burn_rate || 0}% burn
          </Text>
        </Flex>
        <Box mr="sm" style={{ flexGrow: 1 }}>
          <Text size="xl" fw={600}>
            {info.song_name}
          </Text>
          <Text c="dimmed">{info.artist_name}</Text>
          {!isAlltime && (
            <Button
              size="xs"
              variant="subtle"
              color="gray"
              onClick={() => setOpen(!isOpen)}
              style={{
                padding: "2px",
                fontWeight: 400,
              }}
            >
              {!isOpen ? "view" : "close"} all-time stats
            </Button>
          )}
        </Box>
        <StatBlocks
          score={openStats.score}
          samples={openStats.sample_size}
          rating={openStats.formatted_rating || "?"}
        />
        {openStats.conviction && (
          <Flex gap="5px">
            <RectangleBlock
              more
              fillPercentage={openStats.conviction.more * 20}
              value={openStats.conviction.more}
            />
            <RectangleBlock
              fillPercentage={openStats.conviction.less * 20}
              value={openStats.conviction.less}
            />
          </Flex>
        )}
      </Flex>
      {isOpen && (
        <Box>
          <Divider mt="lg" mb="lg" />
          <Flex gap="md" align="center">
            <Flex direction="column" align="center">
              <SongBurnIcon burn={info.all_time_burn_rate} />
              <Text c="dimmed" size="xs">
                {info.all_time_burn_rate || 0}% burn
              </Text>
            </Flex>
            <Box mr="sm" style={{ flexGrow: 1 }}>
              <Text size="lg" fw={600}>
                All-Time Stats
              </Text>
            </Box>
            <StatBlocks
              score={info.all_time_score}
              samples={info.all_time_sample_size}
              rating={info.all_time_formatted_rating}
            />
            {info.all_time_conviction && (
              <Flex gap="5px">
                <RectangleBlock
                  more
                  active={!isLess}
                  fillPercentage={info.all_time_conviction.more * 20}
                  value={info.all_time_conviction.more}
                />
                <RectangleBlock
                  active={isLess}
                  fillPercentage={info.all_time_conviction.less * 20}
                  value={info.all_time_conviction.less}
                />
              </Flex>
            )}
          </Flex>
        </Box>
      )}
    </>
  );
}

const RectangleBlock = ({ more = false, value, fillPercentage }) => {
  return (
    <Flex align="center" direction="column">
      <Svg more={more} fillPercentage={fillPercentage} />
      <Text
        c="dimmed"
        size="xs"
        align="center"
        style={{ lineHeight: "1.05", marginTop: "0.25em" }}
      >
        {more ? "more" : "less"}
        {value > 0 && (
          <>
            <br />
            {value}
          </>
        )}
      </Text>
    </Flex>
  );
};

const StatBlocks = ({ score, samples, rating }) => {
  const isLess = rating ? rating.indexOf("L") > -1 : false;

  return (
    <Flex gap="lg">
      <Flex style={{ minWidth: "100px" }} direction="column" align="center">
        <Title order={3}>{score}</Title>
        <Flex gap="xs">
          <IconScoreboard size={18} />
          <Text size="xs">Score</Text>
        </Flex>
      </Flex>
      <Flex style={{ minWidth: "100px" }} direction="column" align="center">
        <Title order={3}>{abbreviateNumber(samples)}</Title>
        <Flex gap="xs">
          <IconHeadphones size={18} />
          <Text size="xs">Samples</Text>
        </Flex>
      </Flex>
      <Flex style={{ minWidth: "100px" }} direction="column" align="center">
        <Title order={3}>{rating}</Title>
        <Flex gap="xs">
          {isLess ? <IconThumbDown size={18} /> : <IconThumbUp size={18} />}
          <Text size="xs">Rating</Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

const Svg = ({ more, fillPercentage }) => {
  const bases = {
    width: 35,
    height: 60,
  };
  const svgProps = {
    width: bases.width,
    height: bases.height,
    real_height: bases.height / 2,
    real_width: bases.width / 2,
    color_background: "#eee",
    color_tick: "hsla(46, 100%, 0%, 0.35)",
  };

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      xmlns:svgjs="http://svgjs.dev/svgjs"
      viewBox={`0 0 ${svgProps.real_width} ${svgProps.real_height}`}
      width={`${svgProps.width}`}
      height={`${svgProps.height}`}
      opacity="1"
      style={{
        borderRadius: "2px",
        background: svgProps.color_background,
        border: "1px solid hsla(46, 100%, 0%, 0.15)",
      }}
    >
      <defs>
        <linearGradient
          gradientTransform="rotate(0, 0.5, 0.5)"
          x1="50%"
          y1="0%"
          x2="50%"
          y2="50%"
          id="gradientMore"
        >
          <stop stopColor="#33A036" offset="0%" stopOpacity="1"></stop>
          <stop stopColor="#52C755" stopOpacity="1" offset="100%"></stop>
        </linearGradient>
        <linearGradient
          gradientTransform="rotate(0, 0.5, 0.5)"
          x1="50%"
          y1="0%"
          x2="50%"
          y2="50%"
          id="gradientLess"
        >
          <stop stopColor="#dc1c13" offset="0%" stopOpacity="1"></stop>
          <stop stopColor="#ea4c46" offset="50%" stopOpacity="1"></stop>
        </linearGradient>
      </defs>
      <rect
        width={`${svgProps.width}`}
        height={`${svgProps.height}`}
        fill={more ? "url(#gradientMore)" : "url(#gradientLess)"}
        x="0"
        y={
          fillPercentage >= 100
            ? "0%"
            : fillPercentage <= 0
            ? "100%"
            : `${100 - fillPercentage}%`
        }
      ></rect>
      <g transform="translate(0,0)">
        {/* <line
          x1="0"
          x2="30"
          y1="0.25"
          y2="0.25"
          stroke={svgProps.color_tick}
          strokeWidth="0.25"
        /> */}
        {[1, 2, 3, 4].map((m, i) => (
          <line
            key={i}
            x1="0"
            x2="30"
            y1={svgProps.real_height * (0.2 * (i + 1))}
            y2={svgProps.real_height * (0.2 * (i + 1))}
            stroke={svgProps.color_tick}
            strokeWidth="0.25"
          />
        ))}
        {/* <line
          x1="0"
          x2="30"
          y1={svgProps.real_height * 0.4}
          y2={svgProps.real_height * 0.4}
          stroke={svgProps.color_tick}
          strokeWidth="0.25"
        />
        <line
          x1="0"
          x2="30"
          y1={svgProps.real_height * 0.6}
          y2={svgProps.real_height * 0.6}
          stroke={svgProps.color_tick}
          strokeWidth="0.25"
        />
        <line
          x1="0"
          x2="30"
          y1={svgProps.real_height * 0.8}
          y2={svgProps.real_height * 0.8}
          stroke={svgProps.color_tick}
          strokeWidth="0.25"
        /> */}
        {/* <line
          x1="0"
          x2="30"
          y1="19.75"
          y2="19.75"
          stroke={svgProps.color_tick}
          strokeWidth="0.25"
        /> */}
      </g>
    </svg>
  );
};
