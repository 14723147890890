import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Button, Group, Card, Image, Text, TextInput } from "@mantine/core";
import toast from "react-hot-toast";
import { IconMap } from "@tabler/icons-react";

import { PaginatedList } from "@components/shared";

import entityIcon from "@util/entityIcon";
import entityColor from "@util/entityColor";

export default function OrganizationList({
  items = null,
  requestInfo = {
    url: `/retrieve-organizations/`,
    data: {},
  },
  refresh,
  removeButtonRequestInfo = null,
}) {
  const [searchValue, setSearchValue] = useState("");

  const reqData = {
    ...requestInfo.data,
    search_value: searchValue,
    refresh,
  };

  return (
    <div>
      {requestInfo && items === null && (
        <TextInput
          placeholder="Search for an organization..."
          size="lg"
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
        />
      )}
      <PaginatedList
        tableData={items}
        requestInfo={{
          url: requestInfo.url,
          data: reqData,
        }}
        responseMapping={(r) =>
          r.map((m) => ({
            ...m,
            link_url: `/organizations/${m.id}`,
          }))
        }
        displayFormatter={(item) => (
          <Card
            component={Link}
            to={item.original.link_url}
            key={item.original.id}
          >
            {item.original.image && (
              <Card.Section>
                <Image
                  src={item.original.image}
                  height={250}
                  alt={`${item.original.title} photo`}
                />
              </Card.Section>
            )}
            <Text fw={700}>{item.original.name}</Text>
            {item.original.city && (
              <Group>
                {/* <IconMap size={24} /> */}
                <Text>
                  {item.original.city}, {item.original.state_province}
                </Text>
              </Group>
            )}
            {removeButtonRequestInfo && (
              <div>
                <RemoveButton
                  orgId={item.original.id}
                  requestInfo={removeButtonRequestInfo}
                  onSuccess={() => true}
                />
              </div>
            )}
          </Card>
        )}
      />
    </div>
  );
}

const RemoveButton = ({ requestInfo, orgId, onSuccess }) => {
  const [loading, setLoading] = useState(false);

  function onClick() {
    const req = {
      ...requestInfo.data,
    };

    setLoading(true);

    axios
      .post(`/organizations/${orgId}/associate/`, req)
      .then(() => {
        setLoading(false);
        toast.success("Removed!");
        onSuccess();
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  return (
    <Button
      mt="md"
      onClick={onClick}
      size="xs"
      variant="light"
      color="red"
      loading={loading}
      leftSection={entityIcon.trash()}
      radius="lg"
    >
      Remove
    </Button>
  );
};
