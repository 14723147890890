import React, { useEffect, useState } from "react";
import { TextInput, Button, Flex, Text, Divider } from "@mantine/core";
import styled from "styled-components";

import { urlValidation } from "@util/validation";

export default function StationStreamManagement({ value, onChange }) {
  const validated = new RegExp(urlValidation).test(value);

  return (
    <div>
      <TextInput
        placeholder="Stream URL"
        onChange={(e) => onChange(e.target.value)}
        value={value}
      />
      {validated && (
        <React.Fragment>
          <Flex align="center" mt="sm">
            <Text mr="sm" fw={600}>
              Test stream
            </Text>
            <StyledAudio>
              <audio controls>
                <source src={value} />
                Your browser doesn't support radio streaming
              </audio>
            </StyledAudio>
          </Flex>
        </React.Fragment>
      )}
    </div>
  );
}

const StyledAudio = styled.div`
  audio {
    border-radius: 10px;
    height: 25px;
  }
`;
