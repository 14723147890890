import React from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Button, Text, TextInput } from "@mantine/core";

import CustomInputComponent from "./CustomInputComponent";

export default function BasicForm(props) {
  let initialValues = {};
  let schemas = {};

  if (!props.fields.length) {
    return null;
  }

  props.fields.forEach((f) => {
    if (f.grouped) {
      f.fields.forEach((field) => {
        initialValues[field.name] = field.initialValue;
        schemas[field.name] = field.schema();
      });
    } else {
      initialValues[f.name] = f.initialValue;
      schemas[f.name] = f.schema();
    }
  });

  const schema = Yup.object().shape(schemas);

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={(values, { resetForm }) => {
          props.onSubmit(values);
          // resetForm();
        }}
        enableReinitialize
      >
        {({ errors, touched }) => (
          <Form>
            <TextInput
              id="requiredReferralId"
              name="requiredReferralId"
              style={{
                display: "none",
              }}
            />
            {props.fields.map((f, i) => {
              return (
                <Field
                  addressLookup={f.addressLookup}
                  addressLookupCountries={f.addressLookupCountries}
                  addressKeys={f.addressKeys}
                  checkbox={f.checkbox}
                  component={CustomInputComponent}
                  datepicker={f.datepicker}
                  disabled={f.disabled}
                  file={f.file}
                  ignoreZip={f.ignoreZip}
                  key={i}
                  label={f.label}
                  labelUrl={f.labelUrl}
                  mask={f.mask}
                  multiple={f.multiple}
                  name={f.name}
                  options={f.options}
                  placeholder={f.placeholder}
                  radio={f.radio}
                  readOnly={f.readOnly}
                  required={f.required}
                  select={f.select}
                  search={f.search}
                  textarea={f.textarea}
                  timepicker={f.timepicker}
                  toggle={f.toggle}
                  zip={f.zip}
                />
              );
            })}
            {Object.keys(touched).some((r) =>
              Object.keys(errors).includes(r)
            ) && (
              <Text size="sm" c="red" mt="sm" fw={600}>
                {props.formErrorText ||
                  "Please resolve the errors above before continuing"}
              </Text>
            )}
            <div className="form-actions" style={{ marginTop: "1em" }}>
              {props.children}
              <Button
                type="submit"
                disabled={props.submitDisabled}
                loading={props.loading}
                {...props.buttonProps}
              >
                {props.buttonText}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

BasicForm.defaultProps = {
  buttonText: "Save",
  buttonProps: {},
  fields: [],
  showButton: true,
  submitDisabled: false,
};
