import React, { useState, useEffect } from "react";
import axios from "axios";
import { Button } from "@mantine/core";
import toast from "react-hot-toast";
import * as Yup from "yup";

import { ManagerForm } from "./";
import { BasicForm } from "@components/shared";

export default function ManagerAssociate({
  locationId,
  organizationId,
  onSuccess,
}) {
  const [loading, setLoading] = useState(false);
  const [creating, setCreating] = useState(false);
  const [email, setEmail] = useState("");
  const [managerJoinData, setManagerJoinData] = useState(null);

  function lookupManagerEmail(formData) {
    setLoading(true);
    setEmail(formData.email);

    const req = {
      ...formData,
    };

    if (locationId) req.location_id = locationId;
    if (organizationId) req.organization_id = organizationId;

    axios
      .post(`/manager-email-check/`, req)
      .then(({ data }) => {
        setLoading(false);
        if (data.response[0].manager_id) {
          setManagerJoinData(data.response[0]);
        } else {
          setCreating(true);
        }
      })
      .catch((err) => {
        toast.error(err);
        setLoading(false);
      });
  }

  function onManagerCreate() {
    setCreating(false);
    setEmail("");
    onSuccess();
  }

  const fields = [
    {
      name: "email",
      label: "Email",
      initialValue: "",
      required: true,
      schema: () =>
        Yup.string().email("Not a valid email").required("Required"),
    },
  ];

  if (managerJoinData) {
    return (
      <div>
        {[null, 1].includes(managerJoinData.status) && (
          <React.Fragment>
            <p>
              {managerJoinData.status === null && (
                <React.Fragment>
                  We found a manager for the email <b>{email}</b>
                </React.Fragment>
              )}
              {managerJoinData.status === 1 && (
                <React.Fragment>
                  The email <b>{email}</b> has received an invite.
                </React.Fragment>
              )}
            </p>
            <AssociateButton
              reqData={{
                manager_id: managerJoinData.manager_id,
                organization_id: organizationId,
                location_id: locationId,
              }}
              url={`/managers/${managerJoinData.manager_id}/add-to-entity/`}
              buttonText={
                managerJoinData.status === null
                  ? "Send Invite"
                  : "Resend Invite"
              }
              onSuccess={() => {
                setCreating(false);
                setEmail("");
                setManagerJoinData(null);
                onSuccess();
              }}
            />
          </React.Fragment>
        )}
        {managerJoinData.status === 2 && (
          <React.Fragment>
            <p>
              The email <b>{email}</b> is already associated. No further action
              is necessary.
            </p>
            <Button
              fullWidth
              onClick={() => {
                setCreating(false);
                setEmail("");
                setManagerJoinData(null);
              }}
              variant="light"
            >
              Close
            </Button>
          </React.Fragment>
        )}
        {(managerJoinData.status === 3 || managerJoinData.status === 4) && (
          <React.Fragment>
            <p>
              The email <b>{email}</b> has been removed.
            </p>
            <AssociateButton
              url={`/managers/${managerJoinData.manager_id}/add-to-entity/`}
              reqData={{
                manager_id: managerJoinData.manager_id,
                organization_id: organizationId,
                location_id: locationId,
              }}
              buttonText="Invite Again"
              onSuccess={() => {
                toast.success("Sent!");
                setEmail("");
                setManagerJoinData(null);
                setCreating(true);
                onSuccess();
              }}
            />
          </React.Fragment>
        )}
      </div>
    );
  }

  if (creating) {
    return (
      <ManagerForm
        organizationId={organizationId}
        locationId={locationId}
        onSuccess={onManagerCreate}
        email={email}
      />
    );
  }

  return (
    <BasicForm
      buttonProps={{
        fullWidth: true,
      }}
      loading={loading}
      onSubmit={lookupManagerEmail}
      fields={fields}
    />
  );
}

export const AssociateButton = ({
  buttonText = "Submit",
  url,
  onSuccess,
  reqData = {},
  buttonProps = {
    fullWidth: true,
  },
}) => {
  const [loading, setLoading] = useState(false);

  function onClick() {
    setLoading(true);

    axios
      .post(url, reqData)
      .then(() => {
        toast.success("Sent");
        setLoading(false);
        onSuccess();
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  return (
    <Button
      {...buttonProps}
      disabled={loading}
      loading={loading}
      onClick={onClick}
    >
      {buttonText}
    </Button>
  );
};
