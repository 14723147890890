import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import {
  Anchor,
  Badge,
  Box,
  Button,
  Center,
  Group,
  Flex,
  Card,
  SegmentedControl,
  TextInput,
  Text,
  Checkbox,
  LoadingOverlay,
  rem,
} from "@mantine/core";
import {
  IconArrowsUpDown,
  IconRefresh,
  IconPlus,
  IconTrash,
} from "@tabler/icons-react";

import { Counter, EmptyMessage } from "@components/shared";
import entityIcon from "@util/entityIcon";

export default function PrizeLootItems({
  prizeId,
  locationId,
  organizationId,
}) {
  const [lootItems, setLootItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [tabValue, setTabValue] = useState("list");
  const [entityName, setEntityName] = useState("");

  useEffect(() => {
    fetchItems();
  }, []);

  function fetchItems() {
    setLoading(true);
    axios
      .get(`/prizes/${prizeId}/`)
      .then(({ data }) => {
        if (data.response[0].loot_items.length === 0) {
          setTabValue("add");
        } else {
          setTabValue("list");
        }
        setLootItems(data.response[0].loot_items);
        setLoading(false);
        setEntityName(data.response[0].entity_name);
      })
      .catch((err) => {
        setLootItems([]);
        setLoading(false);
      });
  }

  const addedLootItemIds = lootItems.map((m) => m.loot_item_id);

  return (
    <div>
      <SegmentedControl
        mb="lg"
        value={tabValue}
        onChange={setTabValue}
        data={[
          {
            label: (
              <Center style={{ gap: 10 }}>
                <IconArrowsUpDown style={{ width: rem(16), height: rem(16) }} />
                <span>Manage Items</span>
              </Center>
            ),
            value: "list",
          },
          {
            label: (
              <Center style={{ gap: 10 }}>
                <IconPlus style={{ width: rem(16), height: rem(16) }} />
                <span>Add Items</span>
              </Center>
            ),
            value: "add",
          },
          {
            label: (
              <Center style={{ gap: 10 }}>
                <IconTrash style={{ width: rem(16), height: rem(16) }} />
                <span>Remove Items</span>
              </Center>
            ),
            value: "remove",
          },
        ]}
      />
      <Box>
        {tabValue === "list" && (
          <div>
            {lootItems.length > 0 && (
              <LootItemList
                prizeId={prizeId}
                onSuccess={() => fetchItems()}
                currentItems={lootItems}
              />
            )}
          </div>
        )}
        {tabValue === "add" && (
          <div>
            <AddLootItems
              addedLootItemIds={addedLootItemIds}
              prizeId={prizeId}
              locationId={locationId}
              organizationId={organizationId}
              entityName={entityName}
              onSuccess={() => {
                fetchItems();
              }}
            />
          </div>
        )}
        {tabValue === "remove" && (
          <div>
            <RemoveLootItems
              prizeId={prizeId}
              currentItems={lootItems}
              onSuccess={() => {
                fetchItems();
              }}
            />
          </div>
        )}
      </Box>
    </div>
  );
}

function LootItemList({ currentItems = [], prizeId, onSuccess }) {
  const [items, setItems] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    setItems(
      currentItems.map((m) => ({
        ...m,
        count: m.quantity,
      }))
    );
  }, [JSON.stringify(currentItems)]);

  function onItemAllocationChange(itemId, count) {
    const newItems = [...items];
    const newItem = newItems.find((f) => f.loot_item_id === itemId);
    if (!newItem) return;

    newItem.count = count;
    setItems([...newItems]);
  }

  function onSubmitClick() {
    setSubmitting(true);

    const changed = items
      .filter((f) => {
        const foundItem = currentItems.find(
          (i) => i.loot_item_id === f.loot_item_id
        );
        if (!foundItem || f.count === foundItem.quantity) return;
        return f;
      })
      .map((m) => {
        const obj = {
          loot_item_id: m.loot_item_id,
          prize_id: prizeId,
        };
        const foundItem = currentItems.find(
          (i) => i.loot_item_id === m.loot_item_id
        );
        if (!foundItem) return;
        if (m.count < foundItem.quantity) {
          obj.add = false;
          obj.quantity = foundItem.quantity - m.count;
        } else {
          obj.add = true;
          obj.quantity = m.count - foundItem.quantity;
        }
        return obj;
      });

    const req = {
      loot_items: changed,
    };

    axios
      .post(`/prizes/${prizeId}/adjust-loot-quantity/`, req)
      .then(() => {
        onSuccess();
        setSubmitting(false);
        toast.success("Updated!");
      })
      .catch((err) => {
        setSubmitting(false);
        toast.error(err);
      });
  }

  const filteredItems = searchValue.length
    ? items.filter((f) =>
        f.name.toLowerCase().indexOf(searchValue.toLowerCase()) > -1
          ? true
          : false
      )
    : items;

  return (
    <React.Fragment>
      <TextInput
        placeholder="Search"
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        size="md"
        mb="sm"
      />
      <OptionsWrapper>
        {filteredItems.map((item) => {
          const quantityAvailableToAdd =
            item.quantity + item.quantity_available;

          return (
            <React.Fragment>
              <Card key={item.id} p="xs" mb="xs">
                <Flex>
                  <Group style={{ flexGrow: 4 }}>
                    <Text fw={600}>{item.name}</Text>
                    <Badge variant="light" color="gray" size="xs">
                      {item.quantity_available} more available
                    </Badge>
                  </Group>
                  <Box
                    style={{
                      flexGrow: 1,
                      display: "flex",
                      justifyContent: "flex-end",
                      minWidth: "150px",
                    }}
                  >
                    <Counter
                      unlimited={false}
                      minValue={1}
                      maxValue={quantityAvailableToAdd}
                      value={item.count}
                      onChange={(e) =>
                        onItemAllocationChange(item.loot_item_id, e)
                      }
                    />
                  </Box>
                </Flex>
              </Card>
            </React.Fragment>
          );
        })}
      </OptionsWrapper>
      <Group mb="xs" position="right">
        <Button
          variant="subtle"
          color="gray"
          size="xs"
          onClick={() => {
            setItems(
              currentItems.map((m) => ({
                ...m,
                count: m.quantity,
              }))
            );
          }}
        >
          revert changes
        </Button>
      </Group>
      <Button
        fullWidth
        size="lg"
        color="green"
        onClick={onSubmitClick}
        // loading={submitting}
        mt="lg"
      >
        Save
      </Button>
    </React.Fragment>
  );
}

function AddLootItems({
  addedLootItemIds,
  entityName,
  prizeId,
  organizationId,
  locationId,
  onSuccess,
}) {
  const [inventory, setInventory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [creating, setCreating] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [sortMethod, setSortMethod] = useState("quantity");

  const selectedItems = inventory.filter((f) => f.count > 0);
  const managerInfo = useSelector((state) => state.manager);

  useEffect(() => {
    fetchInventory();
  }, []);

  function fetchInventory() {
    const req = {
      page: 0,
      page_size: 1000,
    };

    if (organizationId) req.organization_id = organizationId;
    if (locationId) req.location_id = locationId;

    setLoading(true);
    axios
      .post(`/retrieve-loot-locker/`, req)
      .then(({ data }) => {
        setInventory(
          data.response[0].data
            .map((m) => ({
              ...m,
              count: 0,
            }))
            .filter((f) => !addedLootItemIds.includes(f.id))
          // .sort((a, b) => (a.name > b.name ? 1 : -1))
        );
        setLoading(false);
      })
      .catch((err) => {
        setInventory([]);
        setLoading(false);
      });
  }

  function onItemAddRemove(item) {
    const newInventory = [...inventory];
    const newItem = newInventory.find((f) => f.id === item.id);

    if (newItem && newItem.count > 0) {
      newItem.count = 0;
      setInventory(newInventory);
    } else {
      newItem.count += 1;
      setInventory(newInventory);
    }
  }

  function onItemAllocationChange(itemId, count) {
    const newInventory = [...inventory];
    const newItem = newInventory.find((f) => f.id === itemId);

    newItem.count = count;
    setInventory(newInventory);
  }

  function onSubmitClick() {
    setSubmitting(true);

    const req = {
      loot_items: selectedItems.map((m) => ({
        add: true,
        loot_item_id: m.id,
        prize_id: prizeId,
        quantity: m.count,
      })),
    };

    axios
      .post(`/prizes/${prizeId}/adjust-loot-quantity/`, req)
      .then(() => {
        onSuccess();
        setSubmitting(false);
        setCreating(false);
      })
      .then(() => {
        fetchInventory();
      })
      .catch((err) => {
        setSubmitting(false);
        toast.error(err);
      });
  }

  const selectedInventoryIds = selectedItems.map((m) => m.id);
  const filteredItems = (
    searchValue.length
      ? inventory.filter((f) =>
          f.name.toLowerCase().indexOf(searchValue.toLowerCase()) > -1
            ? true
            : false
        )
      : inventory
  ).sort((a, b) =>
    sortMethod === "quantity"
      ? b.quantity_available - a.quantity_available
      : a.name.toLowerCase() > b.name.toLowerCase()
      ? 1
      : -1
  );

  return (
    <div>
      <Flex mb="xs" align="center">
        <Text size="xs">
          Check out{" "}
          <Anchor
            component={Link}
            target="_blank"
            to={
              managerInfo
                ? `/loot-locker`
                : organizationId
                ? `/organizations/${organizationId}/loot-locker`
                : `/locations/${locationId}/loot-locker`
            }
          >
            {managerInfo ? "loot locker" : `${entityName}'s loot locker`}
          </Anchor>
        </Text>
        <Group position="right" style={{ flexGrow: 1 }}>
          <Button
            size="xs"
            color="gray"
            variant="subtle"
            onClick={fetchInventory}
            leftSection={<IconRefresh size={14} />}
            ml="sm"
          >
            refresh loot locker
          </Button>
          {/* <Button
            onClick={() => setCreating(!creating)}
            size="xs"
            color="gray"
            variant="light"
          >
            {creating ? "view existing" : "create new"}
          </Button> */}
        </Group>
      </Flex>
      {creating ? (
        <LootItemOneOff
          onSuccess={() => {
            setCreating(false);
          }}
        />
      ) : (
        <Box position="relative">
          <LoadingOverlay visible={loading} />
          <TextInput
            placeholder="Search"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            size="md"
            mb="sm"
          />
          <Flex gap="xs" mb="sm">
            <Button
              variant={sortMethod === "quantity" ? "light" : "subtle"}
              size="xs"
              color="gray"
              onClick={() => setSortMethod("quantity")}
            >
              Sort by quantity available
            </Button>
            <Button
              variant={sortMethod === "name" ? "light" : "subtle"}
              size="xs"
              color="gray"
              onClick={() => setSortMethod("name")}
            >
              Sort by name
            </Button>
          </Flex>
          <OptionsWrapper>
            {filteredItems.map((item) => {
              const quantityAvailableToAdd = item.quantity_available;
              const canAdd = quantityAvailableToAdd > 0;

              return (
                <Card mb="xs" key={item.id} p="xs">
                  <Flex>
                    <Group style={{ flexGrow: 4 }}>
                      <Checkbox
                        onChange={() => onItemAddRemove(item)}
                        checked={selectedInventoryIds.includes(item.id)}
                        label={item.name}
                        size="md"
                        style={{ fontWeight: "600" }}
                        disabled={!canAdd}
                      />
                      {canAdd === 0 ? (
                        <Badge color="red" size="xs" variant="light">
                          none available
                        </Badge>
                      ) : (
                        <Badge size="xs" color="gray" variant="light">
                          {quantityAvailableToAdd} available
                        </Badge>
                      )}
                    </Group>
                    <Box
                      style={{
                        flexGrow: 1,
                        display: "flex",
                        justifyContent: "flex-end",
                        opacity: selectedInventoryIds.includes(item.id)
                          ? 1
                          : 0.0,
                      }}
                    >
                      <Counter
                        unlimited={false}
                        minValue={1}
                        maxValue={quantityAvailableToAdd}
                        value={item.count}
                        onChange={(e) =>
                          selectedInventoryIds.includes(item.id)
                            ? onItemAllocationChange(item.id, e)
                            : false
                        }
                      />
                    </Box>
                  </Flex>
                </Card>
              );
            })}
          </OptionsWrapper>
        </Box>
      )}
      {!creating && (
        <Button
          fullWidth
          size="lg"
          color="green"
          disabled={selectedInventoryIds.length === 0}
          onClick={onSubmitClick}
          loading={submitting}
          mt="lg"
        >
          Add items{" "}
          {selectedInventoryIds.length > 0
            ? `(${selectedInventoryIds.length})`
            : ""}
        </Button>
      )}
    </div>
  );
}

function RemoveLootItems({ currentItems, prizeId, onSuccess }) {
  const [selectedItems, setSelectedItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);

  function onItemAddRemove(item) {
    const newSelectedItems = [...selectedItems];
    const newItem = newSelectedItems.find(
      (f) => f.loot_item_id === item.loot_item_id
    );

    if (newItem) {
      setSelectedItems(
        newSelectedItems.filter((f) => f.loot_item_id !== item.loot_item_id)
      );
    } else {
      setSelectedItems([...newSelectedItems, item]);
    }
  }

  function onSubmitClick() {
    setSubmitting(true);

    const req = {
      loot_items: selectedItems.map((m) => ({
        add: false,
        loot_item_id: m.loot_item_id,
        prize_id: prizeId,
        quantity: m.quantity,
      })),
    };

    axios
      .post(`/prizes/${prizeId}/adjust-loot-quantity/`, req)
      .then(() => {
        onSuccess();
        setSubmitting(false);
      })
      .catch((err) => {
        setSubmitting(false);
        toast.error(err);
      });
  }

  const selectedItemIds = selectedItems.map((m) => m.loot_item_id);

  return (
    <div>
      <OptionsWrapper>
        {currentItems.map((item) => {
          return (
            <Card mb="xs" key={item.loot_item_id} p="xs">
              <Flex>
                <Group style={{ flexGrow: 4 }}>
                  <Checkbox
                    onChange={() => onItemAddRemove(item)}
                    checked={selectedItemIds.includes(item.loot_item_id)}
                    label={item.name}
                    size="md"
                    style={{ fontWeight: "600" }}
                  />
                  <Badge size="xs" color="gray" variant="light">
                    {item.quantity} allocated
                  </Badge>
                </Group>
              </Flex>
            </Card>
          );
        })}
      </OptionsWrapper>
      <Button
        fullWidth
        size="lg"
        color="red"
        disabled={selectedItemIds.length === 0}
        onClick={onSubmitClick}
        loading={submitting}
        mt="lg"
      >
        Remove items{" "}
        {selectedItemIds.length > 0 ? `(${selectedItemIds.length})` : ""}
      </Button>
    </div>
  );
}

const OptionsWrapper = ({ children }) => {
  return (
    <Box
      style={{
        background: "var(--mantine-color-gray-1)",
        padding: "var(--mantine-spacing-xs)",
        border: "1px solid #ccc",
        overflowY: "auto",
        maxHeight: "300px",
        minHeight: "300px",
      }}
    >
      {children}
    </Box>
  );
};

const LootItemOneOff = ({ prizeId, onSuccess }) => {
  const [formValues, setFormValues] = useState({
    name: "",
    quantity: "",
    prize_id: prizeId,
  });

  return (
    <div>
      <TextInput
        label="Name"
        value={formValues.name}
        onChange={(e) =>
          setFormValues({
            ...formValues,
            name: e.target.value,
          })
        }
      />
      <TextInput
        label="Quantity"
        value={formValues.quantity}
        onChange={(e) =>
          setFormValues({
            ...formValues,
            quantity: e.target.value,
          })
        }
      />
      <Button
        fullWidth
        mt="lg"
        color="green"
        size="lg"
        disabled={!formValues.name || !formValues.quantity}
      >
        Create and add to prize
      </Button>
    </div>
  );
};
