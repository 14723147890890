import React, { useState, useEffect } from "react";
import { SegmentedControl } from "@mantine/core";
import axios from "axios";
import { Link } from "react-router-dom";
import {
  Card,
  Text,
  Title,
  Flex,
  Button,
  TextInput,
  Badge,
} from "@mantine/core";
import toast from "react-hot-toast";

import { LocationSelect } from "@components/Location";
import { OrganizationSelect } from "@components/Organization";

import { ManagementLayout, PaginatedList } from "@components/shared";
import entityColor from "@util/entityColor";
import entityIcon from "@util/entityIcon";

export default function SubscriptionParticipants({ id }) {
  const [entity, setEntity] = useState("all");
  const [participants, setParticipants] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    fetchData();
  }, []);

  function fetchData() {
    axios
      .get(`/subscriptions/${id}/participants/`)
      .then(({ data }) => {
        setParticipants(data.response);
      })
      .catch((err) => {
        setError(err);
        setParticipants(null);
      });
  }

  const filteredResults = (
    entity === "all"
      ? participants
      : entity === "location"
      ? participants.filter((f) => f.location_id)
      : participants.filter((f) => f.organization_id)
  ).filter((f) => f.name.toLowerCase().indexOf(searchValue.toLowerCase()) > -1);

  const locationIds = participants
    .filter((f) => f.location_id)
    .map((m) => m.location_id);
  const organizationIds = participants
    .filter((f) => f.organization_id)
    .map((m) => m.organization_id);

  return (
    <div>
      <ManagementLayout
        dataComponent={
          <div>
            <TextInput
              placeholder="Search..."
              size="lg"
              mb="sm"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
            <SegmentedControl
              data={[
                { label: "All", value: "all" },
                { label: "Locations", value: "location" },
                { label: "Organizations", value: "org" },
              ]}
              mb="xs"
              value={entity}
              onChange={setEntity}
            />
            <PaginatedList
              tableData={filteredResults}
              displayFormatter={(item) => (
                <Card key={item.original.id}>
                  <Flex gap="xs" mb="sm">
                    <Text
                      component={Link}
                      to={
                        item.original.organization_id
                          ? `/organizations/${item.original.organization_id}`
                          : `/locations/${item.original.location_id}`
                      }
                      fw={600}
                      target="_blank"
                    >
                      {item.original.name}
                    </Text>
                    {item.original.location_id && (
                      <Badge
                        variant="light"
                        color={entityColor.location}
                        leftSection={entityIcon.location()}
                      >
                        Location
                      </Badge>
                    )}
                    {item.original.organization_id && (
                      <Badge
                        variant="light"
                        color={entityColor.organization}
                        leftSection={entityIcon.organization()}
                      >
                        Organization
                      </Badge>
                    )}
                  </Flex>
                  <div>
                    <RemoveButton
                      onSuccess={fetchData}
                      reqData={{
                        organization_id: item.original.organization_id,
                        location_id: item.original.location_id,
                        subscription_id: id,
                      }}
                    />
                  </div>
                </Card>
              )}
            />
          </div>
        }
        createComponent={
          <Card
            style={{
              overflow: "visible",
            }}
          >
            <Title order={4}>Add Participant</Title>
            <AddForm
              locationIds={locationIds}
              orgIds={organizationIds}
              onSuccess={fetchData}
              subscriptionId={id}
            />
          </Card>
        }
      />
    </div>
  );
}

function AddForm({ locationIds, orgIds, onSuccess, subscriptionId }) {
  const [orgId, setOrgId] = useState("");
  const [locationId, setLocationId] = useState("");
  const [loading, setLoading] = useState(false);

  function resetForm() {
    setOrgId("");
    setLocationId("");
  }

  function onSubmit() {
    setLoading(true);

    const req = {
      subscription_id: subscriptionId,
      location_id: locationId,
      organization_id: orgId,
    };

    axios
      .post(`/subscriptions/${subscriptionId}/participants/`, req)
      .then(() => {
        setLoading(false);
        toast.success("Added!");
        resetForm();
        onSuccess();
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  return (
    <div>
      <OrganizationSelect
        disabled={locationId ? true : false}
        disabledIds={orgIds}
        onChange={(e) => {
          setOrgId(e);
          setLocationId("");
        }}
        value={orgId}
      />
      <LocationSelect
        disabled={orgId ? true : false}
        disabledIds={locationIds}
        value={locationId}
        onChange={(e) => {
          setOrgId("");
          setLocationId(e);
        }}
      />
      <Button
        loading={loading}
        fullWidth
        mt="sm"
        disabled={!orgId && !locationId}
        onClick={onSubmit}
      >
        Submit
      </Button>
    </div>
  );
}

function RemoveButton({ reqData, onSuccess }) {
  const [loading, setLoading] = useState(false);

  function onSubmit() {
    setLoading(true);

    const req = { ...reqData };

    axios
      .post(`/remove-subscription-participant/`, req)
      .then(() => {
        setLoading(false);
        toast.success("Removed!");
        onSuccess();
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  return (
    <Button onClick={onSubmit} size="xs" color="gray" variant="light">
      Remove
    </Button>
  );
}
