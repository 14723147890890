import React, { useState, useEffect } from "react";
import axios from "axios";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Button, Table, Text, Flex, Switch, Box } from "@mantine/core";
import toast from "react-hot-toast";

export default function UserEmailStreams({ userLocationId, userOrgId }) {
  const queryClient = useQueryClient();
  const url = userOrgId
    ? `/user-organizations/${userOrgId}/data-grabber/`
    : `/user-locations/${userLocationId}/data-grabber/`;
  const queryKey = userOrgId
    ? `user${userOrgId}EmailStreams`
    : `user${userLocationId}EmailStreams`;
  const { data: streams, isLoading } = useQuery({
    initialData: [],
    queryKey: [queryKey],
    queryFn: async () =>
      axios
        .post(url, {
          context: `user_email_streams`,
          user_location_id: userLocationId,
          user_organization_id: userOrgId,
        })
        .then(({ data }) => {
          return data.response;
        })
        .catch((err) => {
          throw err;
        }),
  });

  const fetchData = () => queryClient.invalidateQueries([queryKey]);

  return (
    <div>
      <Text mb="md" fw={600}>
        Email Preferences
      </Text>
      {streams.map((stream) => (
        <Box key={stream.id} mb="lg">
          <EmailStream
            stream={stream}
            userLocationId={userLocationId}
            userOrgId={userOrgId}
            fetchData={fetchData}
          />
        </Box>
      ))}
      {!isLoading && streams.length === 0 && <Text>No email streams</Text>}
    </div>
  );
}

const EmailStream = ({ stream, userLocationId, userOrgId, fetchData }) => {
  const [isOpen, setOpen] = useState(false);
  const [active, setActive] = useState(false);

  useEffect(() => {
    setActive(stream.active);
  }, [JSON.stringify(stream)]);

  function onStreamChange() {
    setActive(!active);

    const req = {
      user_email_stream_id: stream.id,
      user_location_id: userLocationId,
      user_organization_id: userOrgId,
    };

    const url = userOrgId
      ? `/user-organizations/${userOrgId}/toggle-email-stream/`
      : `/user-locations/${userLocationId}/toggle-email-stream/`;

    axios
      .post(url, req)
      .then(() => {
        fetchData();
      })
      .catch((err) => {
        fetchData();
        toast.error(err);
      });
  }

  return (
    <>
      <Flex
        direction={{ base: "column", md: "row" }}
        align={{ base: "flex-start", md: "center" }}
        gap="xs"
      >
        <Switch
          checked={active}
          onChange={() => onStreamChange()}
          disabled={stream.can_reactivate ? false : true}
        />
        <div style={{ flexGrow: 1 }}>
          {Object.keys(streamVarietyDescription).includes(
            stream.variety_formatted
          ) ? (
            <Text fw={600}>
              {streamVarietyDescription[stream.variety_formatted]}
            </Text>
          ) : (
            <Text fw={600}>{stream.variety_formatted}</Text>
          )}
        </div>
        <Button
          size="xs"
          onClick={() => {
            setOpen(!isOpen);
          }}
          variant="light"
        >
          View Events
        </Button>
      </Flex>
      {isOpen && (
        <Box
          mt="sm"
          style={{
            overflowY: "auto",
            maxHeight: "250px",
          }}
        >
          <EmailStreamEvents
            streamId={stream.id}
            userLocationId={userLocationId}
            userOrgId={userOrgId}
          />
        </Box>
      )}
    </>
  );
};

const EmailStreamEvents = ({ streamId, userLocationId, userOrgId }) => {
  const url = userOrgId
    ? `/user-organizations/${userOrgId}/data-grabber/`
    : `/user-locations/${userLocationId}/data-grabber/`;
  const { data: events, isLoading } = useQuery({
    initialData: [],
    queryKey: [`emailStreams${streamId}`],
    queryFn: async () =>
      axios
        .post(url, {
          context: `user_email_stream_events`,
          user_location_id: userLocationId,
          user_organization_id: userOrgId,
          user_email_stream_id: streamId,
        })
        .then(({ data }) => {
          return data.response;
        })
        .catch((err) => {
          throw err;
        }),
  });

  return (
    <Table striped>
      <Table.Thead>
        <Table.Tr>
          <Table.Th>Action</Table.Th>
          <Table.Th>Date</Table.Th>
          <Table.Th>By</Table.Th>
          <Table.Th>Comment</Table.Th>
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>
        {events.map((event) => (
          <Table.Tr key={event.id}>
            <Table.Td>{event.variety_formatted}</Table.Td>
            <Table.Td>{new Date(event.created_at).toLocaleString()}</Table.Td>
            <Table.Td>{event.by_hash ? event.by_hash.full_name : ""}</Table.Td>
            <Table.Td>
              {event.deactivation_reason_formatted
                ? event.deactivation_reason_formatted
                : ""}
            </Table.Td>
          </Table.Tr>
        ))}
        {events.length === 0 && (
          <Table.Tr>
            <Table.Td>No events yet</Table.Td>
          </Table.Tr>
        )}
      </Table.Tbody>
    </Table>
  );
};

const streamVarietyDescription = {
  marketing:
    "Updates about things we know you like. We try hard to be selective.",
  news_and_events: "General news, updates, and events.",
  offers:
    "Offers are sent to small, specific groups of people. We only send you offers we're pretty sure you'll like.",
};
