import React, { useEffect, useState } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { IconSwitchHorizontal } from "@tabler/icons-react";
import {
  Box,
  Button,
  Group,
  Modal,
  ScrollArea,
  Text,
  Title,
  TextInput,
} from "@mantine/core";

import LogoutButton from "@components/Auth/LogoutButton";

function ManagerSetSession({
  locations,
  orgs,
  sessionLocationId,
  sessionOrgId,
}) {
  if (
    locations.length === 0 &&
    orgs.length === 0 &&
    !sessionLocationId &&
    !sessionOrgId
  ) {
    return (
      <Modal opened withCloseButton={false}>
        <Text fw={600} mb="lg">
          There are no locations or organizations for you to manage at this
          time.
        </Text>
        <LogoutButton text="Logout" />
      </Modal>
    );
  }
  const [isOpen, setOpen] = useState(false);
  const [searchValues, setSearchValues] = useState({
    location: "",
    org: "",
  });

  const showModal = [...locations, ...orgs].length > 1;

  useEffect(() => {
    if (!sessionLocationId && !sessionOrgId && showModal) {
      setOpen(true);
    }
  }, [sessionLocationId, sessionOrgId]);

  useEffect(() => {
    if (sessionLocationId || sessionOrgId) return;

    if (locations.length === 1 && orgs.length === 0) {
      onSwitchClick({
        location_id: locations[0].location_id,
        text_only: locations[0].text_only,
      });
    }

    if (orgs.length === 1 && locations.length === 0) {
      onSwitchClick({ organization_id: orgs[0].organization_id });
    }
  }, []);

  function onClose() {
    if (!sessionLocationId && !sessionOrgId) return;
    setOpen(false);
  }

  function onSwitchClick(switchInfo) {
    axios
      .post(`/set-managed/`, switchInfo)
      .then(() => {
        if (switchInfo.text_only) {
          window.location = `/locations/${switchInfo.location_id}`;
        } else {
          window.location = "/";
        }
      })
      .catch((err) => {
        window.location.reload();
      });
  }

  const activeLocation =
    sessionLocationId &&
    locations.find((f) => f.location_id === sessionLocationId)
      ? locations.find((f) => f.location_id === sessionLocationId)
      : null;

  const activeOrg =
    sessionOrgId && orgs.find((f) => f.organization_id === sessionOrgId)
      ? orgs.find((f) => f.organization_id === sessionOrgId)
      : null;

  const filteredLocations = locations.filter(
    (f) =>
      f.name.toLowerCase().indexOf(searchValues.location.toLowerCase()) > -1
  );

  const filteredOrgs = orgs.filter(
    (f) => f.name.toLowerCase().indexOf(searchValues.org.toLowerCase()) > -1
  );

  if (!showModal) return null;

  return (
    <React.Fragment>
      <Group mb="lg">
        <Button
          title="Change Location"
          onClick={() => setOpen(true)}
          leftSection={<IconSwitchHorizontal />}
          size="xs"
          variant="light"
          color="gray"
          fullWidth
        >
          {activeLocation ? (
            <span>{activeLocation.name}</span>
          ) : activeOrg ? (
            <span>{activeOrg.name}</span>
          ) : (
            <span>Manage</span>
          )}
        </Button>
      </Group>
      <Modal
        title="Who would you like to manage?"
        opened={isOpen}
        onClose={onClose}
        centered
      >
        {locations.length > 0 && (
          <React.Fragment>
            <Title order={5} mb="xs">
              Locations ({locations.length})
            </Title>
            <TextInput
              placeholder="search for location"
              mb="sm"
              onChange={(e) =>
                setSearchValues({
                  ...searchValues,
                  location: e.target.value,
                })
              }
              value={searchValues.location}
            />
            <Box
              style={{
                maxHeight: "300px",
                overflowY: "auto",
              }}
            >
              {filteredLocations
                .sort((a, b) => (a.name > b.name ? 1 : -1))
                .map((p, i) => {
                  const isActiveLocation =
                    activeLocation &&
                    activeLocation.location_id === p.location_id;

                  return (
                    <Button
                      key={i}
                      variant={!isActiveLocation ? "light" : null}
                      disabled={isActiveLocation}
                      onClick={() =>
                        onSwitchClick({
                          location_id: p.location_id,
                          text_only: p.text_only,
                        })
                      }
                      fullWidth
                      mb="xs"
                    >
                      {p.name}
                    </Button>
                  );
                })}
            </Box>
          </React.Fragment>
        )}
        {orgs.length > 0 && (
          <React.Fragment>
            <Title order={5} mb="xs">
              Organizations ({orgs.length})
            </Title>
            <TextInput
              placeholder="search for organization"
              mb="sm"
              onChange={(e) =>
                setSearchValues({
                  ...searchValues,
                  org: e.target.value,
                })
              }
              value={searchValues.org}
            />
            {filteredOrgs.map((p, i) => {
              const isActiveOrganization =
                activeOrg && activeOrg.organization_id === p.organization_id;

              return (
                <Button
                  key={i}
                  variant={!isActiveOrganization ? "light" : null}
                  disabled={isActiveOrganization}
                  onClick={() =>
                    onSwitchClick({ organization_id: p.organization_id })
                  }
                  fullWidth
                  mb="xs"
                >
                  {p.name}
                </Button>
              );
            })}
          </React.Fragment>
        )}
      </Modal>
    </React.Fragment>
  );
}

function mapStateToProps(state) {
  return {
    locations: state.manager ? state.manager.locations : [],
    orgs: state.manager ? state.manager.organizations : [],
    sessionLocationId: state.manager ? state.manager.location_id : null,
    sessionOrgId: state.manager ? state.manager.organization_id : null,
  };
}

export default connect(mapStateToProps)(ManagerSetSession);
