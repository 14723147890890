import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import {
  Card,
  Image,
  Group,
  SegmentedControl,
  Text,
  TextInput,
} from "@mantine/core";
import { IconMap } from "@tabler/icons-react";

import { PaginatedList } from "@components/shared";
import entityColor from "@util/entityColor";

export const statusButtons = [
  { label: "Active", value: "active", color: "" },
  { label: "Inactive", value: "inactive", color: entityColor.contest },
].map((m, i) => ({
  ...m,
  key: i,
}));
export default function SubscriptionList({
  items = null,
  requestInfo = {
    url: `/retrieve-subscriptions/`,
    data: {},
  },
  refresh,
}) {
  const [searchValue, setSearchValue] = useState("");
  const [status, setStatus] = useState("active");

  const reqData = {
    ...requestInfo.data,
    search_value: searchValue,
    refresh,
    status,
  };

  return (
    <div>
      <TextInput
        placeholder="Search for a subscription..."
        size="lg"
        mb="sm"
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
      />
      <SegmentedControl
        value={status}
        onChange={(e) => setStatus(e)}
        data={statusButtons}
        mb="sm"
      />
      <PaginatedList
        tableData={items}
        requestInfo={{
          url: requestInfo.url,
          data: reqData,
        }}
        responseMapping={(r) =>
          r.map((m, i) => ({
            ...m,
            key: i,
            link_url: `/subscriptions/${m.id}`,
          }))
        }
        displayFormatter={(item) => (
          <Card key={item.original.id}>
            <Text fw={700} component={Link} to={item.original.link_url}>
              {item.original.customer?.legal_name}
            </Text>
            {item.original.customer?.city && (
              <Group>
                <IconMap size={24} />
                <Text>
                  {item.original.customer?.city},{" "}
                  {item.original.customer?.state_province}
                </Text>
              </Group>
            )}
          </Card>
        )}
      />
    </div>
  );
}
