import React from "react";
import { SubscriptionList } from "@components/Subscription";

export default function SubscriptionIndex() {
  return (
    <div>
      <SubscriptionList />
    </div>
  );
}
