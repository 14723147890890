import React, { useState, useEffect } from "react";
import axios from "axios";
import { Modal, Box, Card, Button, Switch, Group } from "@mantine/core";
import { useSelector } from "react-redux";
import { useParams, useMatch, Route, Routes, Outlet } from "react-router-dom";
import toast from "react-hot-toast";

import {
  SubscriptionMeta,
  SubscriptionParticipants,
  SubscriptionAuthAuthority,
} from "@components/Subscription";
import { ErrorView, Subnav, PageTitle } from "@components/shared";
import entityIcon from "@util/entityIcon";
import entityColor from "@util/entityColor";

export default function SubscriptionDetail() {
  const [error, setError] = useState(null);
  const [info, setInfo] = useState(null);
  const params = useParams();
  const { id } = params;

  useEffect(() => {
    fetchData();
  }, []);

  function fetchData() {
    axios
      .get(`/subscriptions/${id}/`)
      .then(({ data }) => {
        setInfo(data.response[0]);
      })
      .catch((err) => {
        setError(err);
        setInfo(null);
      });
  }

  if (error) {
    return <ErrorView text={error} />;
  }

  if (!info) return null;

  return (
    <div>
      <PageTitle
        title={info?.customer?.legal_name}
        subtitle={"Subscription"}
        icon={entityIcon.subscription()}
        iconColor={entityColor.subscription}
      />
      <Subnav
        buttonColor={"blue"}
        links={[
          { to: `/subscriptions/${id}`, text: "Detail" },
          { to: `/subscriptions/${id}/participants`, text: "Participants" },
        ]}
      />
      <Routes>
        <Route
          exact
          path="/"
          element={
            <>
              <SubscriptionAuthAuthority
                authAuthority={info.auth_authority}
                id={id}
                onSuccess={fetchData}
              />
            </>
          }
        />
        <Route
          exact
          path="/participants"
          element={
            <>
              <SubscriptionParticipants id={id} />
            </>
          }
        />
      </Routes>
    </div>
  );
}
