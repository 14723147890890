const entityColor = {
  admin: "red",
  artist: "blue",
  campaign: "cyan",
  contest: "pink",
  effort: "orange",
  email: "teal",
  keyword: "yellow",
  library: "lime",
  location: "green",
  loot: "teal",
  organization: "blue",
  manager: "violet",
  play: "grape",
  organization: "blue",
  report: "indigo",
  ticket: "red",
  song: "teal",
  user: "teal",
  api: "red",
  session: "grey",
  request: "orange",
  general: "indigo",
  subscription: "gray",
};

export default entityColor;
