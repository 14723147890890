export const emailStreamLabels = {
  3: "Marketing",
  4: "Offers",
  5: "News and Events",
  6: "Broadcast",
};

export const defaultEmailStreams = [
  { label: "Marketing", name: "marketing", variety: 3 },
  { label: "Offers", name: "offers", variety: 4 },
  { label: "New and Events", name: "news_and_events", variety: 5 },
].map((m) => ({
  ...m,
  label: emailStreamLabels[m.variety],
}));

export const frenchEmailStreamLabels = {
  3: "Marketing",
  4: "Offres",
  5: "Nouvelles et événements",
  6: "Broadcast",
};
