import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import {
  Button,
  Card,
  Image,
  Group,
  SegmentedControl,
  Text,
  TextInput,
  Flex,
} from "@mantine/core";
import { IconMap } from "@tabler/icons-react";
import toast from "react-hot-toast";

import { DataFilters, PaginatedList } from "@components/shared";
import entityIcon from "@util/entityIcon";
import entityColor from "@util/entityColor";

const filterArr = [
  {
    text: "Status",
    key_name: "status",
    default_value: [1, 2],
    options: [
      { text: "Active", value: 1 },
      { text: "Inactive", value: 2 },
      { text: "Deleted", value: 3 },
    ],
  },
].map((m, i) => ({
  ...m,
  all_values: m.options.map((mm) => mm.value),
  key: i + 1,
}));

export const varietyButtons = [
  { label: "All", value: "all", color: "" },
  { label: "Station", value: "1", color: entityColor.contest },
  { label: "Dealer", value: "2", color: entityColor.campaign },
  { label: "Retail", value: "3", color: entityColor.effort },
].map((m, i) => ({
  ...m,
  key: i,
}));

const defaultFilters = filterArr.reduce((acc, cur) => {
  acc[cur.key_name] = cur.default_value;
  return acc;
}, {});

export default function LocationList({
  isIndex,
  items = null,
  requestInfo = {
    url: `/retrieve-locations/`,
    data: {},
  },
  refresh,
}) {
  const [searchValue, setSearchValue] = useState("");
  const [filters, setFilters] = useState(defaultFilters);
  const [refresh2, setRefresh2] = useState(false);
  const [variety, setVariety] = useState("all");
  const [status, setStatus] = useState("1");

  const isAdmin = useSelector((state) => state.admin) ? true : false;
  const managerInfo = useSelector((state) => state.manager);

  const reqData = {
    // ...formattedFilters,
    ...requestInfo.data,
    search_value: searchValue,
    refresh,
    refresh2,
    variety: variety === "all" ? [1, 2, 3] : variety,
    status,
  };

  function onFilterChange(keyName, value) {
    setFilters({
      ...filters,
      [keyName]: filters[keyName].includes(value)
        ? [...filters[keyName]].filter((f) => f !== value)
        : [...filters[keyName], value],
    });
  }

  return (
    <div>
      {requestInfo && (
        <React.Fragment>
          <TextInput
            placeholder="Search for a location..."
            size="lg"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
          {/* <DataFilters
            onChange={onFilterChange}
            values={filters}
            items={filterArr}
            title={"Filter by status"}
          /> */}
        </React.Fragment>
      )}
      <Flex mt="sm" mb="sm" gap="sm">
        <SegmentedControl
          value={status}
          onChange={(e) => setStatus(e)}
          data={[
            { label: "Active", value: "1" },
            { label: "Inactive", value: "2" },
            { label: "Deleted", value: "3" },
          ]}
        />
        <SegmentedControl
          value={variety}
          onChange={(e) => setVariety(e)}
          data={varietyButtons}
        />
      </Flex>
      <PaginatedList
        tableData={items}
        requestInfo={{
          url: requestInfo.url,
          data: reqData,
        }}
        responseMapping={(r) =>
          r.map((m, i) => ({
            ...m,
            key: i,
            link_url: `/locations/${m.id}`,
          }))
        }
        displayFormatter={(item) => (
          <Card key={item.original.id}>
            {item.original.image && (
              <Card.Section>
                <Image
                  src={item.original.image}
                  height={250}
                  alt={`${item.original.title} photo`}
                />
              </Card.Section>
            )}
            <Text fw={700} component={Link} to={item.original.link_url}>
              {item.original.name}
            </Text>
            {item.original.city && (
              <Group>
                {/* <IconMap size={24} /> */}
                <Text>
                  {item.original.city}, {item.original.state_province}
                </Text>
              </Group>
            )}
            {requestInfo &&
              requestInfo.data &&
              !isIndex &&
              (isAdmin || (managerInfo && managerInfo.organization_id)) && (
                <div>
                  <RemoveButton
                    reqData={{
                      ...requestInfo.data,
                      location_id: item.original.id,
                      remove: true,
                    }}
                    locationId={item.original.id}
                    onSuccess={() => setRefresh2(!refresh2)}
                  />
                </div>
              )}
          </Card>
        )}
      />
    </div>
  );
}

const RemoveButton = ({ locationId, reqData, onSuccess }) => {
  const [loading, setLoading] = useState(false);

  function onClick() {
    const req = {
      ...reqData,
    };

    setLoading(true);

    axios
      .post(`/locations/${locationId}/associate/`, req)
      .then(() => {
        setLoading(false);
        toast.success("Removed!");
        onSuccess();
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  return (
    <Button
      mt="md"
      onClick={onClick}
      size="xs"
      variant="light"
      color="red"
      loading={loading}
      leftSection={entityIcon.trash()}
      radius="lg"
    >
      Remove
    </Button>
  );
};
