import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Alert, Group, Loader, Select, Text, Pagination } from "@mantine/core";
import {
  getCoreRowModel,
  useReactTable,
  getFilteredRowModel,
  getPaginationRowModel,
} from "@tanstack/react-table";
import { motion } from "framer-motion";

import { BasicGrid, EmptyMessage } from "@components/shared";

export default function PaginatedList({
  tableData = null,
  displayFormatter = (d) => <div>data</div>,
  responseMapping = null,
  requestInfo = {
    url: "",
    data: {},
  },
}) {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pageCount, setPageCount] = useState(-1);
  const [total, setTotal] = useState(0);
  const [error, setError] = useState(null);
  const [init, setInit] = useState(false);

  const CancelToken = axios.CancelToken;
  const cancelRef = useRef(null);

  const dynamicConfig = {
    manualPagination: tableData ? false : true,
  };

  if (!tableData) {
    dynamicConfig.pageCount = pageCount;
  }

  const table = useReactTable({
    ...dynamicConfig,
    data: items,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: {
      pagination: {
        pageSize: 10,
      },
    },
  });

  useEffect(() => {
    if (tableData) {
      setItems(tableData);
      setTotal(tableData.length);
      setPageCount(tableData.length / table.getState().pagination.pageSize);
      setLoading(false);
    }
  }, [JSON.stringify(tableData)]);

  useEffect(() => {
    resetTablePage().then(() => {
      fetchData();
    });
  }, [JSON.stringify(requestInfo)]);

  useEffect(() => {
    if (tableData && tableData.length > 0) {
      setPageCount(tableData.length / table.getState().pagination.pageSize);
    }
  }, [JSON.stringify(table?.getState().pagination?.pageSize)]);
  // console.log(table.getState().pagination.pageIndex);

  useEffect(() => {
    if (!init) return;
    fetchData();
  }, [JSON.stringify(table.getState().pagination)]);

  async function resetTablePage() {
    try {
      table.setPageIndex(0);
    } catch (error) {}
  }

  function fetchData() {
    if (tableData !== null) return;

    setError(null);

    const req = {
      ...requestInfo.data,
      page_size: table.getState().pagination.pageSize || 10,
      page:
        table.getState().pagination.pageIndex === -1
          ? 0
          : table.getState().pagination.pageIndex,
    };

    if ([null, undefined].includes(req.page) || !req.page) {
      req.page = 0;
    }

    const cancel = cancelRef.current;
    if (cancel) cancel();

    setLoading(true);

    axios
      .post(requestInfo.url, req, {
        cancelToken: new CancelToken(function executor(c) {
          cancelRef.current = c;
        }),
      })
      .then(({ data }) => {
        setItems(responseMapping(data.response[0].data));
        setPageCount(data.response[0].page_count);
        if (data.response[0].total !== undefined) {
          setTotal(data.response[0].total);
        } else {
          setTotal(data.response[0].data.length);
        }
      })
      .then(() => {
        setInit(true);
        setLoading(false);
        setError(null);
        window.scrollTo(0, 0);
      })
      .catch((err) => {
        setLoading(false);
        // setItems([]);
        // setError(err);
        setInit(true);
      });
  }

  return (
    <div style={{ marginTop: "0.5em" }}>
      {!loading && total === 0 && !error && <EmptyMessage />}
      <BasicGrid columns={1} gap="10px">
        {table.getRowModel().rows.map((item, i) => (
          <AnimatedItem key={i}>{displayFormatter(item)}</AnimatedItem>
        ))}
      </BasicGrid>
      <Group mt="lg">
        <Pagination
          total={pageCount}
          onChange={(e) => table.setPageIndex(e - 1)}
          size="lg"
          disabled={loading}
          value={table.getState().pagination.pageIndex + 1}
        />
        <Select
          size="sm"
          style={{ width: "110px" }}
          value={`${table.getState().pagination.pageSize}`}
          onChange={(e) => table.setPageSize(parseInt(e))}
          data={[5, 10, 20, 30, 40, 50].map((pageSize) => ({
            label: `${pageSize} items`,
            value: `${pageSize}`,
            placeholder: "Select a pagesize",
          }))}
        />
        {loading && <Loader size="xs" variant="dots" />}
      </Group>
      <Text mb="lg" size="sm" mt="sm">
        {total} total item{total === 1 ? "" : "s"}
      </Text>
    </div>
  );
}

const AnimatedItem = ({ children, isVisible }) => {
  return (
    <motion.div
      initial={{ y: 10, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ y: -10, opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      {children}
    </motion.div>
  );
};
