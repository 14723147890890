import React, { useRef } from "react";
import { Box, Badge, Group, Card, Image, Text } from "@mantine/core";
import Autoplay from "embla-carousel-autoplay";
import { Carousel } from "@mantine/carousel";
import { IconPhoto } from "@tabler/icons-react";

const imageHeight = 300;
const boxHeight = imageHeight;

export default function PrizeCard({
  prizeData,
  showEntrants = false,
  children,
}) {
  const showSettings =
    prizeData.settings && JSON.stringify(prizeData.settings) !== "{}"
      ? true
      : false;

  const autoplay = useRef(Autoplay({ delay: 10000 }));

  return (
    <Card
      mb="sm"
      style={{
        overflow: "hidden",
      }}
    >
      <Group>
        <Text size="xl" fw={600}>
          {prizeData.name}
        </Text>
        {!showEntrants && !prizeData.can_edit && (
          <Badge variant="light" size="xs" color="gray">
            no longer editable
          </Badge>
        )}
        {showSettings && prizeData.settings.finalists === true && (
          <Badge variant="light" size="xs" color="gray">
            {prizeData.settings.number_of_finalists} finalist
            {prizeData.settings.number_of_finalists === 1 ? "" : "s"}{" "}
            {prizeData.settings.draw_winner_from_finalists
              ? ", winner will be drawn"
              : ""}
          </Badge>
        )}
        {showEntrants &&
          prizeData.eligible_entrants_remaining !== undefined && (
            <Badge variant="light" size="xs" color="gray">
              {prizeData.eligible_entrants_remaining} entrants remaining
            </Badge>
          )}
      </Group>
      <Text color="gray.6">{prizeData.description}</Text>
      <Text color="gray.6" size="xs">
        estimated retail value: ${prizeData.value_formatted}
      </Text>
      {/* {prizeData.loot_items.length > 0 && (
        <List mt="sm" size="sm">
          {prizeData.loot_items.map((m) => (
            <List.Item key={m.loot_item_id}>
              ({m.quantity}x) {m.name}
            </List.Item>
          ))}
        </List>
      )} */}
      {prizeData.loot_items.length > 0 ? (
        <Card.Section mt="sm">
          <Carousel
            // withControls={false}
            withControls={prizeData.loot_items.length > 1}
            plugins={[autoplay.current]}
            // withIndicators={prizeData.loot_items.length > 1}
            withIndicators={false}
            draggable={prizeData.loot_items.length > 1}
            loop
          >
            {prizeData.loot_items.map((m) => (
              <Carousel.Slide key={m.loot_item_id}>
                <Box
                  style={{
                    background: "#000",
                  }}
                >
                  <LootItemPhotos items={m.assets} />
                </Box>
                <Box
                  style={{
                    padding: "0.75rem",
                  }}
                >
                  <Text fw={600} size="lg">
                    ({m.quantity}x) {m.name}
                  </Text>
                  {m.description && (
                    <Text size="sm" mt="3px" color="gray.6">
                      {m.description}
                    </Text>
                  )}
                  {m.value_formatted && (
                    <Text size="sm" color="gray.6">
                      estimated retail value ${m.value_formatted}
                    </Text>
                  )}
                </Box>
              </Carousel.Slide>
            ))}
          </Carousel>
        </Card.Section>
      ) : (
        <Card.Section mt="sm" mb="sm">
          <Box
            style={{
              height: boxHeight,
              textAlign: "center",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              background: "var(--mantine-color-red-7)",
            }}
          >
            <IconPhoto size={60} color="#fff" />
            <Text ml="xs" fw={600} color="gray.1">
              No loot items!
            </Text>
          </Box>
        </Card.Section>
      )}
      {children}
    </Card>
  );
}

export const LootItemPhotos = ({ items = [] }) => {
  const autoplay = useRef(Autoplay({ delay: 2000 }));

  if (!items.length)
    return (
      <Box
        style={{
          height: boxHeight,
          textAlign: "center",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <IconPhoto size={60} color="#fff" />
      </Box>
    );

  return (
    <Carousel
      withControls={false}
      withIndicators={false}
      draggable={false}
      loop
      slidesToScroll={1}
      plugins={[autoplay.current]}
    >
      {items.map((item) => (
        <Carousel.Slide key={item.id}>
          <Image fit="cover" height={imageHeight} src={item.filename_url} />
        </Carousel.Slide>
      ))}
    </Carousel>
  );
};
