import axios from "axios";
import React, { useState, useEffect } from "react";
import { Card, Title, Table } from "@mantine/core";
import { useParams, useMatch, Route, Routes, Outlet } from "react-router-dom";

import { SongRating } from "@components/Song";
import { ErrorView, PageTitle, Subnav } from "@components/shared";

import entityIcon from "@util/entityIcon";
import entityColor from "@util/entityColor";

export default function PlayDetail() {
  const [info, setInfo] = useState(null);
  const [error, setError] = useState(null);
  const params = useParams();
  const { id } = params;

  useEffect(() => {
    fetchData();
  }, []);

  function fetchData() {
    axios
      .get(`/plays/${params.id}/`)
      .then(({ data }) => {
        setInfo(data.response[0]);
      })
      .catch((err) => {
        setError(err);
        setInfo(null);
      });
  }

  if (error) {
    return <ErrorView text={error} />;
  }

  if (!info) return null;

  return (
    <div>
      <PageTitle
        title={info.display_name}
        subtitle={info.variety_formatted}
        icon={entityIcon.play()}
        iconColor={entityColor.play}
      />
      {info.rating_info && (
        <Card mb="lg">
          <SongRating info={info.rating_info} defaultOpen />
        </Card>
      )}
      <Title mb="xs" mt="lg" order={4}>
        Data
      </Title>
      <Card>
        <pre
          style={{
            maxWidth: "100%",
            display: "block",
            wordWrap: "break-word",
            whiteSpace: "pre-wrap",
            overflowX: "auto",
            fontSize: "1.15em",
          }}
        >
          {JSON.stringify(info.data)}
        </pre>
      </Card>
      {info.song && (
        <React.Fragment>
          <Title mb="xs" mt="lg" order={4}>
            Artists
          </Title>
          <Card>
            <Table>
              <Table.Tbody>
                {info.song.artists.map((a) => (
                  <Table.Tr key={a.id}>{a.name}</Table.Tr>
                ))}
              </Table.Tbody>
            </Table>
          </Card>
        </React.Fragment>
      )}
      <React.Fragment>
        <Title mb="xs" mt="lg" order={4}>
          Timing
        </Title>
        <Card>
          <Table>
            <Table.Thead>
              <Table.Tr>
                <Table.Th></Table.Th>
                <Table.Th>start</Table.Th>
                <Table.Th>end</Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              <Table.Tr>
                <Table.Td>posted</Table.Td>
                <Table.Td>
                  {new Date(info.posted_start_time).toLocaleString()}
                </Table.Td>
                <Table.Td>
                  {new Date(info.posted_end_time).toLocaleString()}
                </Table.Td>
              </Table.Tr>
              <Table.Tr>
                <Table.Td>adjusted</Table.Td>
                <Table.Td>
                  {new Date(info.adjusted_start_time).toLocaleString()}
                </Table.Td>
                <Table.Td>
                  {new Date(info.adjusted_end_time).toLocaleString()}
                </Table.Td>
              </Table.Tr>
            </Table.Tbody>
          </Table>
        </Card>
      </React.Fragment>
    </div>
  );
}
