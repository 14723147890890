import React, { useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import * as Yup from "yup";
import { BasicForm } from "@components/shared";

export default function ManagerForm({
  id = null,
  communicationEmail = "",
  email = "",
  firstName = "",
  lastName = "",
  mfa = false,
  organizationId,
  locationId,
  onSuccess = () => {},
}) {
  const [loading, setLoading] = useState(false);

  function onSubmit(formData) {
    setLoading(true);

    if (id) return onUpdate(formData);

    return onCreate(formData);
  }

  function onCreate(formData) {
    const req = {
      ...formData,
    };

    if (locationId) req.location_id = locationId;
    if (organizationId) req.organization_id = organizationId;

    axios
      .post(`/managers/`, req)
      .then(() => setLoading(false))
      .then(() => toast.success("Created!"))
      .then(() => onSuccess())
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  function onUpdate(formData) {
    const req = {
      ...formData,
      manager_id: id,
    };

    axios
      .put(`/managers/${id}/`, req)
      .then(() => setLoading(false))
      .then(() => toast.success("Updated!"))
      .then(() => onSuccess())
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  const fields = [
    {
      name: "first_name",
      label: "First Name",
      initialValue: firstName,
      required: true,
      schema: () => Yup.string().max(50, "Too long!").required("Required"),
    },
    {
      name: "last_name",
      label: "Last Name",
      initialValue: lastName,
      required: true,
      schema: () => Yup.string().max(50, "Too long!").required("Required"),
    },
    {
      name: "email",
      label: "Email",
      initialValue: email,
      required: true,
      // disabled: id && !email,
      schema: () =>
        Yup.string()
          .max(100, "Too long!")
          .email("Not a valid email")
          .required("Required"),
    },
    {
      name: "communication_email",
      label: "Communication Email",
      initialValue: communicationEmail,
      required: false,
      // disabled: true,
      schema: () => Yup.string().email("Not a valid email"),
    },
    // {
    //   name: "mfa",
    //   label: "Multi-Factor Authentication",
    //   initialValue: mfa,
    //   required: false,
    //   schema: () => null,
    //   toggle: true,
    // },
  ]
    .filter((f) =>
      id
        ? !email
          ? !["email"].includes(f.name)
          : true
        : !["communication_email"].includes(f.name)
    )
    .filter((f) => (email ? !["communication_email"].includes(f.name) : true));

  return (
    <BasicForm
      buttonProps={{
        fullWidth: !id ? true : false,
      }}
      loading={loading}
      onSubmit={onSubmit}
      fields={fields}
    />
  );
}
