import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useSearchParams } from "react-router-dom";

export default function SamlAuth() {
  let [searchParams, setSearchParams] = useSearchParams();
  const code = searchParams.get("code");

  if (!code) return null;

  useEffect(() => {
    onSubmit();
  }, []);

  function onSubmit() {
    const req = {
      code,
      saml: true,
      manager: true,
    };

    axios
      .post("/saml-login/", req)
      .then(({ data }) => {
        window.location = "/";
      })
      .catch((err) => {});
  }

  return <React.Fragment></React.Fragment>;
}
