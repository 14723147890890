import React, { useEffect, useState, useRef } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import axios from "axios";
import useInterval from "@util/useInterval";

// Plugin to add a pulsating marker on add point
Highcharts.addEvent(Highcharts.Series, "addPoint", (e) => {
  const point = e.point,
    series = e.target;

  if (!series.pulse) {
    series.pulse = series.chart.renderer.circle().add(series.markerGroup);
  }

  setTimeout(() => {
    series.pulse
      .attr({
        x: series.xAxis.toPixels(point.x, true),
        y: series.yAxis.toPixels(point.y, true),
        r: series.options.marker.radius,
        opacity: 1,
        fill: series.color,
      })
      .animate(
        {
          r: 20,
          opacity: 0,
        },
        {
          duration: 1000,
        }
      );
  }, 1);
});

export default function PlaySplineChart({ currentBurn }) {
  const chartComponentRef = useRef(null);
  const [options, setOptions] = useState({
    chart: {
      height: 300,
      backgroundColor: "transparent",
      type: "spline",
      style: {
        fontFamily: "inherit",
      },
    },
    credits: {
      enabled: false,
    },
    time: {
      useUTC: false,
    },

    title: {
      text: "",
    },
    xAxis: {
      type: "datetime",
      tickPixelInterval: 3000,
      maxPadding: 0.1,
    },

    yAxis: {
      title: {
        text: "Burn",
      },
      plotLines: [
        {
          value: 0,
          width: 1,
          // color: "#808080",
          color: "var(--mantine-color-gray-1)",
        },
      ],
    },

    tooltip: {
      headerFormat: "<b>{series.name}</b><br/>",
      pointFormat: "{point.x:%Y-%m-%d %H:%M:%S}<br/>{point.y:.2f}",
    },

    legend: {
      enabled: false,
    },

    exporting: {
      enabled: false,
    },

    series: [
      {
        name: "",
        lineWidth: 2,
        color: "var(--mantine-color-green-5)",
        data: [
          [new Date().getTime() - 10, 0],
          [new Date().getTime() - 20, 0],
          [new Date().getTime() - 30, 0],
          [new Date().getTime(), 0],
          [new Date().getTime(), 0],
          [new Date().getTime(), 0],
          [new Date().getTime(), 0],
          [new Date().getTime(), 0],
          [new Date().getTime(), 0],
          [new Date().getTime(), 0],
          [new Date().getTime(), 0],
          [new Date().getTime(), 0],
          [new Date().getTime(), 0],
          [new Date().getTime(), 0],
          [new Date().getTime(), 0],
          [new Date().getTime(), 0],
          [new Date().getTime(), 0],
          [new Date().getTime(), 0],
          [new Date().getTime(), 0],
          [new Date().getTime(), 0],
        ],
      },
    ],
  });

  useEffect(() => {
    if (!chartComponentRef) return;
    const chart = chartComponentRef.current.chart;
    const series = chart.series[0];
    series.addPoint(
      [new Date().getTime(), currentBurn],
      true,
      series.data.length <= 19
      // true
    );
  }, [currentBurn]);

  return (
    <HighchartsReact
      highcharts={Highcharts}
      ref={chartComponentRef}
      options={options}
    />
  );
}

PlaySplineChart.defaultProps = {
  data: [],
};
