import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { Button, Modal, Text, TextInput } from "@mantine/core";
import toast from "react-hot-toast";

import { emailValidation } from "@util/validation";

export default function ManagerCommEmail() {
  const managerInfo = useSelector((state) => state.manager);
  const [isOpen, setOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!managerInfo.communication_email) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [JSON.stringify(managerInfo)]);

  if (!isOpen) return null;

  function onSubmit() {
    setLoading(true);

    const req = {
      communication_email: email,
    };

    axios
      .put(`/managers/${managerInfo.manager_id}/communication-email/`, req)
      .then(() => {
        setLoading(false);
        setOpen(false);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  const submitDisabled = !emailValidation.test(email);

  return (
    <Modal title="Email Needed!" opened={isOpen} centered>
      <Text mb="lg">
        We need an email for your Mixer account. This email will be how Mixer
        gets in touch with you regarding your account.
      </Text>
      <TextInput
        label="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <Button
        mt="sm"
        fullWidth
        onClick={onSubmit}
        loading={loading}
        disabled={submitDisabled}
      >
        Save
      </Button>
    </Modal>
  );
}
