import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { Button, Divider, Card, Flex, Space, Title } from "@mantine/core";
import toast from "react-hot-toast";

import { LocationList, BulkLocationDownload, LocationSelect } from "./";
import { BulkAssociate, ManagementLayout } from "@components/shared";

export default function LocationAssociate({
  organizationIds,
  campaignId,
  effortId,
  selectReqData = {},
  disabledIds = [],
  fetchData = () => {},
  bulkData,
  subscriptionId,
}) {
  const [locationId, setLocationId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const managerInfo = useSelector((state) => state.manager);
  const isLocationManager =
    managerInfo && managerInfo.location_id ? true : false;
  const isOrganizationManager =
    managerInfo && managerInfo.organization_id ? true : false;

  const reqInfo = {
    refresh,
  };

  if (organizationIds) reqInfo.organization_ids = organizationIds;
  if (campaignId) reqInfo.campaign_id = campaignId;
  if (effortId) reqInfo.campaign_effort_id = effortId;
  if (subscriptionId) reqInfo.subscription_id = subscriptionId;

  function onSubmit() {
    setLoading(true);

    const req = {
      location_id: locationId,
    };

    if (organizationIds) req.organization_id = organizationIds[0];
    if (campaignId) req.campaign_id = campaignId;
    if (effortId) req.campaign_effort_id = effortId;
    if (subscriptionId) req.subscription_id = subscriptionId;

    axios
      .post(`/locations/${locationId}/associate/`, req)
      .then(() => {
        setLocationId(null);
        setLoading(false);
        setRefresh(!refresh);
        fetchData();
        toast.success("Location added!");
      })
      .catch((err) => {
        toast.error(err);
        setLoading(false);
      });
  }

  if (isLocationManager)
    return (
      <LocationList
        refresh={refresh}
        requestInfo={{
          url: `/retrieve-locations/`,
          data: reqInfo,
        }}
      />
    );

  return (
    <ManagementLayout
      dataComponent={
        <LocationList
          refresh={refresh}
          requestInfo={{
            url: `/retrieve-locations/`,
            data: reqInfo,
          }}
        />
      }
      createComponent={
        <Card
          style={{
            overflow: "visible",
          }}
        >
          <Title order={4}>Add Location</Title>
          <LocationSelect
            disabledIds={disabledIds}
            value={locationId}
            onChange={(e) => setLocationId(e)}
            reqData={selectReqData}
          />
          <Button
            fullWidth
            loading={submitting}
            onClick={onSubmit}
            disabled={!locationId}
            mt="xs"
          >
            Add Location
          </Button>
          {bulkData && !managerInfo && (
            <>
              <Divider mb="lg" mt="lg" />
              <Flex justify="center">
                <BulkAssociate
                  showLocationVarietyFilter={
                    bulkData.show_location_variety_filter
                  }
                  downloadReqData={bulkData.download_request_data}
                  uploadReqData={bulkData.upload_request_data}
                  onSuccess={() => setRefresh(!refresh)}
                />
                <BulkLocationDownload
                  downloadReqData={bulkData.download_request_data}
                />
              </Flex>
            </>
          )}
        </Card>
      }
    />
  );
}
