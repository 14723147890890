import React from "react";
import { Button, Divider, Group } from "@mantine/core";
import { IconTarget, IconHomeStar } from "@tabler/icons-react";
import { shallow } from "zustand/shallow";
import { useTranslation } from "react-i18next";

import UserDashboard from "./UserDashboard";
import UserPreferredLocation from "./UserPreferredLocation";
import { MicrositeUserEmailStreams } from "@components/Email";

import useAppStore from "./microsite-store";

export default function UserView({ fetchUser }) {
  const {
    appConfig,
    emailStreams,
    entityInfo,
    preferredLocation,
    user,
    setEffortEntryVisible,
    setEffort,
    setEmailStreams,
    setPreferredLocation,
    setSelectedEffortId,
  } = useAppStore(
    (state) => ({
      appConfig: state.appConfig,
      emailStreams: state.emailStreams,
      entityInfo: state.entityInfo,
      preferredLocation: state.preferredLocation,
      user: state.user,
      setEffortEntryVisible: state.setEffortEntryVisible,
      setEffort: state.setEffort,
      setEmailStreams: state.setEmailStreams,
      setPreferredLocation: state.setPreferredLocation,
      setSelectedEffortId: state.setUserSelectedEffortId,
    }),
    shallow
  );

  // const showPreferredLocation = entityInfo.location
  //   ? false
  //   : appConfig && appConfig.settings
  //   ? appConfig.settings.preferred_location
  //   : true;

  const showPreferredLocation = entityInfo.preferred_location;

  const { t } = useTranslation();

  if (showPreferredLocation && !preferredLocation) {
    return <UserPreferredLocation fetchUser={fetchUser} />;
  }

  return (
    <React.Fragment>
      <UserDashboard fetchUser={fetchUser} />
      {true && (
        <React.Fragment>
          <Group justify="center" mt="lg">
            <Button
              onClick={() => {
                setSelectedEffortId(null);
                if (entityInfo.campaign_effort_id) {
                  setEffort(null);
                }

                setEffortEntryVisible(true);
              }}
              variant="subtle"
              color="gray"
              leftSection={<IconTarget />}
            >
              {t("user_select_different_campaign_button")}
            </Button>
            {showPreferredLocation && (
              <Button
                onClick={() => setPreferredLocation(null)}
                variant="subtle"
                color="gray"
                leftSection={<IconHomeStar />}
              >
                {preferredLocation
                  ? `${preferredLocation.name}`
                  : t("add_preferred_location_button")}
              </Button>
            )}
          </Group>
          {/* {user && (
            <>
              <Divider mt="lg" mb="lg" />
              <MicrositeUserEmailStreams
                user={user}
                streams={emailStreams}
                onChange={(e) => {
                  setEmailStreams(e);
                }}
              />
            </>
          )} */}
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
