import React, { useState, useEffect } from "react";
import axios from "axios";
import { Card, Select, Text, Button } from "@mantine/core";
import toast from "react-hot-toast";

export default function SubscriptionAuthAuthority({
  id,
  authAuthority,
  onSuccess,
}) {
  const [authMethod, setAuthMethod] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (authAuthority) {
      setAuthMethod(`${authAuthority.variety}`);
    }
  }, [JSON.stringify(authAuthority)]);

  function onSubmit() {
    setLoading(true);

    const req = {
      subscription_id: id,
      auth_authority_id: authAuthority.id,
      variety: authMethod,
    };

    axios
      .put(`/auth-authorities/${authAuthority.id}/`, req)
      .then(() => {
        toast.success("Updated!");
        setLoading(false);
        onSuccess();
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  return (
    <Card>
      <Select
        data={[
          { label: "Email and Password", value: "1" },
          { label: "SAML", value: "2" },
          { label: "Email + SAML", value: "3" },
        ]}
        value={authMethod}
        onChange={(e) => setAuthMethod(e)}
        placeholder="Select one..."
        label="Auth Method"
      />
      {authAuthority && `${authAuthority.variety}` !== authMethod && (
        <Text mt="sm" size="sm" fw={600} c="red">
          You've changed the auth method! Be careful.
        </Text>
      )}
      <div>
        <Button
          disabled={!authMethod}
          mt="sm"
          loading={loading}
          onClick={onSubmit}
        >
          Save
        </Button>
      </div>
    </Card>
  );
}
